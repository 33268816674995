<template>
  <div class="countdown-container">
    剩余时间: {{ Math.floor(countdown / 60) }} 分 {{ countdown % 60 }} 秒
  </div>
  <div class="quiz-container">
    <h1 class="title">中山大学第四届民族宗教知识竞赛</h1>
    <p class="subtitle"></p>
    <div v-for="(quiz, index) in quizzes" :key="quiz.id" class="question-container">
      <!-- 根据题目类型添加标题 -->
      <template v-if="personType === 0">
        <h2 class="subtitle-center" v-if="index === 0">一、单项选择题</h2>
        <h2 class="subtitle-center" v-if="index === 80">二、多项选择题</h2>
        <h2 class="subtitle-center" v-if="index === 110">三、判断题</h2>
      </template>
      <template v-else>
        <h2 class="subtitle-center" v-if="index === 0">一、单项选择题</h2>
        <h2 class="subtitle-center" v-if="index === 80">二、多项选择题</h2>
        <h2 class="subtitle-center" v-if="index === 110">三、判断题</h2>
      </template>
      <!--
      <template v-if="personType === 0">
        <h1 class="title-center" v-if="index === 0 || index === 10 || index === 26 || index === 40 || index === 56">
         # {{ getTitle(index) }}
        </h1>
      </template>
      <template v-else>
        <h1 class="title-center" v-if="index === 0 || index === 10 || index === 26 || index === 40 || index === 56">
         # {{ getTitle(index) }}
        </h1>
      </template>
      -->
      <h3>{{ index + 1 }}. {{ quiz.question }}</h3>

      <!-- 单选题 -->
      <div v-if="quiz.questionType === '单选题'" class="options-container">
        <div
          v-for="(option, key) in JSON.parse(quiz.options)"
          :key="key"
          class="option-block"
          :class="{ 'option-selected': isSelected(quiz.id, key, 'single') }"
          @click="selectOption(quiz.id, key, 'single')"
        >
          <input
            type="radio"
            :name="'quiz' + quiz.id"
            :value="key"
            v-model="answers[quiz.id]"
            class="hidden-radio"
          />
          <label>{{ key }}. {{ option }}</label>
        </div>
      </div>

      <!-- 多选题 -->
      <div v-else-if="quiz.questionType === '多选题'" class="options-container">
        <div
          v-for="(option, key) in JSON.parse(quiz.options)"
          :key="key"
          class="option-block"
          :class="{ 'option-selected': isSelected(quiz.id, key, 'multi') }"
          @click="selectOption(quiz.id, key, 'multi')"
        >
          <input
            type="checkbox"
            :value="key"
            v-model="multiAnswers[quiz.id]"
            class="hidden-checkbox"
          />
          <label>{{ key }}. {{ option }}</label>
        </div>
      </div>

      <!-- 判断题 -->
      <div v-else-if="quiz.questionType === '判断题'" class="options-container">
        <div
          v-for="(option, key) in JSON.parse(quiz.options)"
          :key="key"
          class="option-block"
          :class="{ 'option-selected': isSelected(quiz.id, key, 'single') }"
          @click="selectOption(quiz.id, key, 'single')"
        >
          <input
            type="radio"
            :name="'quiz' + quiz.id"
            :value="key"
            v-model="answers[quiz.id]"
            class="hidden-radio"
          />
          <label>{{ key }}. {{ option }}</label>
        </div>
      </div>
      
    </div>
    <button class="submit-btn" @click="submitAnswers">提交</button>
  </div>
</template>




<style>
.quiz-container {
  padding: 30px;
  max-width: 700px;
  margin: 0 auto;
  user-select: none;
}

.title {
  font-size: 2em;
  text-align: center;
  margin-bottom: 20px;
}

.subtitle {
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 40px;
}

.question-container {
  margin-bottom: 40px;
}

.options-container {
  margin-top: 15px;
}

.option-block {
  display: block;
  width: 300px; /* 设置固定的宽度，根据需要调整 */
  text-align: left; /* 确保文本左对齐 */
  margin: 10px 0;
  padding: 10px 20px;
  border: 1px solid #aaa;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}
h3 {
  text-align: left;
}

.hidden-radio,
.hidden-checkbox {
  display: none;
}

.option-block:hover {
  background-color: #e6e6e6;
}

.option-selected {
  background-color: #d9f7be;
}

.submit-btn {
  display: block; /* 使用 block 代替 inline-block */
  padding: 10px 20px;
  margin: 20px auto; /* 使用 auto 使按钮居中 */
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 400px;
}

.submit-btn:hover {
  background-color: #0056b3;
}

.submit-btn:focus {
  outline: none;
}

.countdown-container {
  position: fixed;
  top: 10px;
  right: 10px;
  background-color: rgba(255, 255, 255, 0.8); 
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);
  z-index: 999;
}

.title-center {
  text-align: center;
  font-size: 24px;
  color: #333;
  margin-top: 20px;
  margin-bottom: 20px;
}
</style>

<script>
import { ref, reactive, onMounted, onUnmounted } from 'vue';
import axios from 'axios';
import { useRouter } from 'vue-router';

export default {
  name: 'Quiz',
  props: ['name', 'studentNumber', 'organization', 'phoneNumber'],
  setup(props) {
    const quizzes = ref([]);
    const answers = reactive({});
    const personType = ref(null);
    const multiAnswers = reactive({});
    const countdown = ref(1800); // 7200 seconds = 120 minutes
    const startTime = ref(Date.now());
    const router = useRouter();

window.alert = function(msg, callback) {
    var div = document.createElement("div");
    div.innerHTML = "<style type=\"text/css\">"
            + ".nbaMask { position: fixed; z-index: 1000; top: 0; right: 0; left: 0; bottom: 0; background: rgba(0, 0, 0, 0.5); }                                                                                                                                                                       "
            + ".nbaMaskTransparent { position: fixed; z-index: 1000; top: 0; right: 0; left: 0; bottom: 0; }                                                                                                                                                                                            "
            + ".nbaDialog { position: fixed; z-index: 5000; width: 80%; max-width: 300px; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); background-color: #fff; text-align: center; border-radius: 8px; overflow: hidden; opacity: 1; color: white; }"
            + ".nbaDialog .nbaDialogHd { padding: .2rem .27rem .08rem .27rem; }                                                                                                                                                                                                                         "
            + ".nbaDialog .nbaDialogHd .nbaDialogTitle { font-size: 17px; font-weight: 400; }                                                                                                                                                                                                           "
            + ".nbaDialog .nbaDialogBd { padding: 0 .27rem; font-size: 15px; line-height: 1.3; word-wrap: break-word; word-break: break-all; color: #000000; }                                                                                                                                          "
            + ".nbaDialog .nbaDialogFt { position: relative; line-height: 48px; font-size: 17px; display: -webkit-box; display: -webkit-flex; display: flex; }                                                                                                                                          "
            + ".nbaDialog .nbaDialogFt:after { content: \" \"; position: absolute; left: 0; top: 0; right: 0; height: 1px; border-top: 1px solid #e6e6e6; color: #e6e6e6; -webkit-transform-origin: 0 0; transform-origin: 0 0; -webkit-transform: scaleY(0.5); transform: scaleY(0.5); }               "
            + ".nbaDialog .nbaDialogBtn { display: block; -webkit-box-flex: 1; -webkit-flex: 1; flex: 1; color: #09BB07; text-decoration: none; -webkit-tap-highlight-color: transparent; position: relative; margin-bottom: 0; }                                                                       "
            + ".nbaDialog .nbaDialogBtn:after { content: \" \"; position: absolute; left: 0; top: 0; width: 1px; bottom: 0; border-left: 1px solid #e6e6e6; color: #e6e6e6; -webkit-transform-origin: 0 0; transform-origin: 0 0; -webkit-transform: scaleX(0.5); transform: scaleX(0.5); }             "
            + ".nbaDialog a { text-decoration: none; -webkit-tap-highlight-color: transparent; }"
            + "</style>"
            + "<div id=\"dialogs2\" style=\"display: none\">"
            + "<div class=\"nbaMask\"></div>"
            + "<div class=\"nbaDialog\">"
            + "    <div class=\"nbaDialogHd\">"
            + "        <strong class=\"nbaDialogTitle\"></strong>"
            + "    </div>"
            + "    <div class=\"nbaDialogBd\" id=\"dialog_msg2\">弹窗内容，告知当前状态、信息和解决方法，描述文字尽量控制在三行内</div>"
            + "    <div class=\"nbaDialogHd\">"
            + "        <strong class=\"nbaDialogTitle\"></strong>"
            + "    </div>"
            + "    <div class=\"nbaDialogFt\">"
            + "        <a href=\"javascript:;\" class=\"nbaDialogBtn nbaDialogBtnPrimary\" id=\"dialog_ok2\">确定</a>"
            + "    </div></div></div>";
    document.body.appendChild(div);
 
    var dialogs2 = document.getElementById("dialogs2");
    dialogs2.style.display = 'block';
 
    var dialog_msg2 = document.getElementById("dialog_msg2");
    dialog_msg2.innerHTML = msg;
 
    // var dialog_cancel = document.getElementById("dialog_cancel");
    // dialog_cancel.onclick = function() {
    // dialogs2.style.display = 'none';
    // };
    var dialog_ok2 = document.getElementById("dialog_ok2");
    dialog_ok2.onclick = function() {
        dialogs2.style.display = 'none';
        callback();
    };
};


    // Block the console
    const blockConsole = (e) => {
      if (e.key === 'F12' || (e.ctrlKey && e.shiftKey && e.key === 'I')) {
        e.preventDefault();
      }
    };

    // Block the context menu
    const blockContextMenu = (e) => {
      e.preventDefault();
    };

    onMounted(() => {
      document.addEventListener('keydown', blockConsole);
      document.addEventListener('contextmenu', blockContextMenu);
      document.addEventListener('copy', preventCopy);
      fetchQuizzes();

      const timer = setInterval(() => {
        const elapsedSeconds = Math.floor((Date.now() - startTime.value) / 1000);
        countdown.value = 1800 - elapsedSeconds;

        if (countdown.value <= 0) {
          alert('时间已到，正在提交您的答案。');
          submitAnswers();
          clearInterval(timer);
        }
      }, 1000);

      onUnmounted(() => {
        clearInterval(timer);
      });
    });

    onUnmounted(() => {
      document.removeEventListener('keydown', blockConsole);
      document.removeEventListener('contextmenu', blockContextMenu);
      document.removeEventListener('copy', preventCopy);
    });

    const preventCopy = (e) => {
        e.preventDefault();
    };

    const selectOption = (quizId, optionKey, type) => {
      if (type === 'single') {
        answers[quizId] = optionKey;
      } else {
        if (!multiAnswers[quizId]) {
          multiAnswers[quizId] = [];
        }
        const index = multiAnswers[quizId].indexOf(optionKey);
        if (index === -1) {
          multiAnswers[quizId].push(optionKey);
        } else {
          multiAnswers[quizId].splice(index, 1);
        }
      }
    }

    const isSelected = (quizId, optionKey, type) => {
      if (type === 'single') {
        return answers[quizId] === optionKey;
      } else {
        return multiAnswers[quizId] && multiAnswers[quizId].includes(optionKey);
      }
    }

    const getTitle = (index) => {
        const titlesForType0 = {
            0: "biaoti1",
            10: "标题二",
            26: "标题三",
            40: "标题四",
            56: "标题五"
        };
        
        const titlesForTypeElse = {
            0: "习近平法治思想、依法治国",
            10: "宪法、民法典、国家安全",
            26: "防范诈骗、法律普及",
            40: "校纪校规类",
            56: "学生党支部和党风廉政"
        };

        if (personType.value === 0) {
            return titlesForType0[index];
        } else {
            return titlesForTypeElse[index];
        }
    };


    const fetchQuizzes = async () => {
      try {
        const response = await axios.post('/api/questions', { studentNumber: props.studentNumber });
        quizzes.value = response.data.questions;
        personType.value = response.data.personType;
      } catch (error) {
        console.error("Error fetching quizzes:", error);
      }
    }

    const submitAnswers = async () => {
      const formattedAnswers = { ...answers };
      for (const key in multiAnswers) {
        formattedAnswers[key] = multiAnswers[key].join('');
      }

    const elapsedTimeInSeconds = (Date.now() - startTime.value) / 1000;
    const formattedElapsedTime = elapsedTimeInSeconds.toFixed(3); // 保留三位小数

    try {
        const response = await axios.post('/api/submit-answers', {
        name: props.name,
        studentNumber: props.studentNumber,
        organization: props.organization,
        phoneNumber: props.phoneNumber,
        answers: formattedAnswers,
        elapsedTime: formattedElapsedTime
        });



        if (response.data.message === "Quiz evaluated!") {
            alert(`提交成功！您的得分是 ${response.data.score} 分。`);
            router.push({ name: 'User' });
        }
    } catch (error) {
        if (error.response && error.response.data.message === "每人最多提交两次") {
            alert("每人最多提交两次");
            router.push({ name: 'User' });
        } else {
            console.error("Error submitting answers:", error);
        }
      }
    }

    return { quizzes, answers, multiAnswers, fetchQuizzes, submitAnswers, selectOption, isSelected, countdown, getTitle };
   },
  mounted() {
    this.fetchQuizzes();
  }
}
</script>
