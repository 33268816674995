<template>
  <div class="admin-container">
    <div class="login-box" v-if="!loggedIn">
      <h2>Admin Login</h2>
      <div class="input-field">
        <label for="username">Username:</label>
        <input id="username" v-model="username" placeholder="Username" />
      </div>
      <div class="input-field">
        <label for="password">Password:</label>
        <input id="password" v-model="password" placeholder="Password" type="password" />
      </div>
      <button class="btn" @click="handleLogin">Login</button>
    </div>

    <div v-if="loggedIn">
      <div class="filter-section">
        <select v-model="userType">
          <option value="all">所有用户</option>
          <option value="student">学生</option>
          <option value="teacher">教职工</option>
        </select>

        <select v-model="organization">
          <option value="all">全部组织</option>
<option value="中国语言文学系党委">中国语言文学系党委</option>
<option value="历史学系党委">历史学系党委</option>
<option value="哲学系党委">哲学系党委</option>
<option value="社会学与人类学学院党委">社会学与人类学学院党委</option>
<option value="博雅学院党总支">博雅学院党总支</option>
<option value="岭南学院党委">岭南学院党委</option>
<option value="外国语学院党">外国语学院党委</option>
<option value="法学院党委">法学院党委</option>
<option value="政治与公共事务管理学院党委">政治与公共事务管理学院党委</option>
<option value="管理学院党委">管理学院党委</option>
<option value="马克思主义学院党委">马克思主义学院党委</option>
<option value="心理学系党委">心理学系党委</option>
<option value="新闻传播学院党委">新闻传播学院党委</option>
<option value="信息管理学院党委">信息管理学院党委</option>
<option value="艺术学院党委">艺术学院党委</option>
<option value="数学学院党委">数学学院党委</option>
<option value="物理学院党委">物理学院党委</option>
<option value="化学学院党委">化学学院党委</option>
<option value="地理科学与规划学院党委">地理科学与规划学院党委</option>
<option value="生命科学学院党委">生命科学学院党委</option>
<option value="材料科学与工程学院党委">材料科学与工程学院党委</option>
<option value="电子与信息工程学院党委">电子与信息工程学院党委</option>
<option value="计算机学院党委">计算机学院党委</option>
<option value="环境科学与工程学院党委">环境科学与工程学院党委</option>
<option value="系统科学与工程学院党委">系统科学与工程学院党委</option>
<option value="中山医学院党委">中山医学院党委</option>
<option value="公共卫生学院党委">公共卫生学院党委</option>
<option value="药学院党委">药学院党委</option>
<option value="护理学院党委">护理学院党委</option>
<option value="体育部党委">体育部党委</option>
<option value="中国语言文学系（珠海）党委">中国语言文学系（珠海）党委</option>
<option value="历史学系（珠海）党委">历史学系（珠海）党委</option>
<option value="哲学系（珠海）党委">哲学系（珠海）党委</option>
<option value="国际金融学院党委">国际金融学院党委</option>
<option value="国际翻译学院党委">国际翻译学院党委</option>
<option value="国际关系学院党委">国际关系学院党委</option>
<option value="旅游学院党委">旅游学院党委</option>
<option value="数学学院（珠海）党委">数学学院（珠海）党委</option>
<option value="物理与天文学院党委">物理与天文学院党委</option>
<option value="大气科学学院党委">大气科学学院党委</option>
<option value="海洋科学学院党委">海洋科学学院党委</option>
<option value="地球科学与工程学院党委">地球科学与工程学院党委</option>
<option value="化学工程与技术学院党委">化学工程与技术学院党委</option>
<option value="海洋工程与技术学院党委">海洋工程与技术学院党委</option>
<option value="中法核工程与技术学院党委">中法核工程与技术学院党委</option>
<option value="土木工程学院党委">土木工程学院党委</option>
<option value="微电子科学与技术学院党委">微电子科学与技术学院党委</option>
<option value="测绘科学与技术学院党委">测绘科学与技术学院党委</option>
<option value="人工智能学院党委">人工智能学院党委</option>
<option value="软件工程学院党委">软件工程学院党委</option>
<option value="医学院党委">医学院党委</option>
<option value="公共卫生学院（深圳）党委">公共卫生学院（深圳）党委</option>
<option value="药学院（深圳）党委">药学院（深圳）党委</option>
<option value="材料学院党委">材料学院党委</option>
<option value="生物医学工程学院党委">生物医学工程学院党委</option>
<option value="电子与通信工程学院党委">电子与通信工程学院党委</option>
<option value="智能工程学院党委">智能工程学院党委</option>
<option value="航空航天学院党委">航空航天学院党委</option>
<option value="农业与生物技术学院党委">农业与生物技术学院党委</option>
<option value="生态学院党委">生态学院党委</option>
<option value="集成电路学院直属党支部">集成电路学院直属党支部</option>
<option value="先进制造学院直属党支部">先进制造学院直属党支部</option>
<option value="先进能源学院直属党支部">先进能源学院直属党支部</option>
<option value="网络空间安全学院直属党支部">网络空间安全学院直属党支部</option>
<option value="商学院直属党支部">商学院直属党支部</option>
<option value="理学院直属党支部">理学院直属党支部</option>
<option value="柔性电子学院直属党支部">柔性电子学院直属党支部</option>
<option value="附属第一医院党委">附属第一医院党委</option>
<option value="孙逸仙纪念医院党委">孙逸仙纪念医院党委</option>
<option value="附属第三医院党委">附属第三医院党委</option>
<option value="中山眼科中心党委">中山眼科中心党委</option>
<option value="肿瘤防治中心党委">肿瘤防治中心党委</option>
<option value="光华口腔医学院·附属口腔医院党委">光华口腔医学院·附属口腔医院党委</option>
<option value="附属第五医院党委">附属第五医院党委</option>
<option value="附属第六医院党委">附属第六医院党委</option>
<option value="附属第七医院党委">附属第七医院党委</option>
<option value="附属第八医院党委">附属第八医院党委</option>
<option value="离退休党工委">离退休党工委</option>
<option value="机关党委">机关党委</option>
<option value="文献与文化遗产管理部党委">文献与文化遗产管理部党委</option>
<option value="产业集团党委">产业集团党委</option>
<option value="南方海洋科学与工程广东省实验室（珠海）党委">南方海洋科学与工程广东省实验室（珠海）党委</option>
<option value="粤港澳发展研究院党委">粤港澳发展研究院党委</option>
        </select>

        <button @click="fetchStudentsData">搜索</button>
      </div>

      <table>
        <thead>
            <tr>
                <th>姓名</th>
                <th>学号/身份证号</th>
                <th>类别</th>
                <th>组织</th>
                <th>联系方式</th>
                <th>最高得分</th>
                <th>用时</th>
            </tr>
        </thead>
        <tbody>
            <tr v-for="(student, index) in students" :key="index">
                <td>{{ student.name }}</td>
                <td>{{ student.studentNumber }}</td>
                <td>{{ student.personType}}</td>
                <td>{{ student.organization }}</td>
                <td>{{ student.phoneNumber }}</td>
                <td>{{ student.score }}</td>
                <td>{{ student.quizDuration }}秒</td>
            </tr>
        </tbody>
      </table>

<button class="btn download-btn" @click="downloadExcel" v-if="students.length">Download as Excel</button>

    </div>
  </div>
</template>




<script>
// 阻止 F12 快捷键打开控制台
document.addEventListener('keydown', function(e) {
    if (e.key === 'F12' || (e.ctrlKey && e.shiftKey && e.key === 'I')) {
        e.preventDefault();
    }
});

// 阻止右键菜单中打开控制台选项
document.addEventListener('contextmenu', function(e) {
    e.preventDefault();
});

import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: '',
      loggedIn: false,
      students: [],
      userType: 'all',
      organization: 'all',
      scoreOrder: 'desc'
    };
  },
  methods: {
    async handleLogin() {
      try {
        const response = await axios.post('/api/admin/login', {
          username: this.username,
          password: this.password
        });
        if (response.data.message === "Login successful!") {
            // 将token保存到localStorage中
            localStorage.setItem('token', response.data.token);
            this.loggedIn = true;
            this.fetchStudentsData();
        }
      } catch (error) {
        console.error("Error logging in:", error);
      }
    },

    async fetchStudentsData() {
      try {
        const token = localStorage.getItem('token');
        const studentData = await axios.get('/api/admin/data', {
          params: {
            userType: this.userType,
            organization: this.organization,
            scoreOrder: this.scoreOrder
          },
          headers: {
            'Authorization': `Bearer ${token}`
          }
        });
        this.students = studentData.data;

      } catch (error) {
        console.error("Error fetching student data:", error);
      }
    },


async downloadExcel() {
    try {
        const token = localStorage.getItem('token'); // 从localStorage中获取token

        const response = await axios.get('/api/admin/down', {
            responseType: 'blob',
            params: {
                userType: this.userType,
                organization: this.organization,
                scoreOrder: this.scoreOrder
            },
            headers: {
                'Authorization': `Bearer ${token}`  // 将token添加到请求头中
            }
        });
        
        const blob = new Blob([response.data], { type: 'application/vnd.ms-excel' });
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = 'students_data.xlsx';
        link.click();
    } catch (error) {
        console.error("Error downloading Excel:", error);
    }
}



  }
}
</script>

<style scoped>
.admin-container {
  font-family: Arial, sans-serif;
  max-width: 800px;
  margin: 50px auto;
  padding: 20px;
  background-color: #f7f7f7;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.login-box {
  text-align: center;
  padding: 20px;
}

.input-field {
  width: 100%;
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ddd;
  border-radius: 5px;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

th, td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

tr:nth-child(even) {
  background-color: #f2f2f2;
}

th {
  background-color: #007BFF;
  color: white;
}

.btn {
  display: inline-block;
  padding: 10px 20px;
  margin-top: 20px;
  background-color: #007BFF;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.btn:hover {
  background-color: #0056b3;
}

.download-btn {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.filter-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}
</style>
