<template>
  <div class="container">
    <div v-if="showIntroduction">
      <!-- 添加图片 -->
      <img src='@/assets/image1.jpg' alt="活动说明图片" class="responsive-img" />

      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;为深入学习贯彻习近平总书记关于做好新时代党的统一战线工作的重要思想，全面贯彻党的民族宗教方针政策，大力推进民族团结进步，引导广大师生树立正确的民族观、宗教观，铸牢中华民族共同体意识，经学校党委同意，决定举办“中山大学第四届民族宗教知识竞赛”，具体事项通知如下：
      </p>

      <h3>一、主题内容</h3>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;以“全面深入学习宣传贯彻党的二十大三中全会精神，有形有感有效铸牢中华民族共同体意识”为主题，开展民族宗教知识竞赛活动。
      </p>
      <h3>二、活动对象</h3>
      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;此活动面向我校全体学生和教职工，请各二级党组织积极动员广大师生踊跃参加。</p>
      <h3>三、活动形式</h3>
      <p>
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;知识竞赛通过线上答题方式进行，参赛者登陆网页后，输入相关信息后即可进行在线答题。
      </p>
      <h3>四、活动时间</h3>

      <p>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2023年9月*日至10月*日</p>

      <h3>五、活动规则</h3>
<p>
    （一）竞赛共设2套试题，每套试题考核时间为30分钟，每套试题各40道题，满分为100分，全部为单选题。每位参赛者最多可参与两次答题，再次答题需重新登录，取最高分参加评奖。<br />
</p><p>
    （二）此次竞赛具体奖项设置如下：<br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;1.集体奖：优秀组织奖15名。以二级党组织为单位，按照组织参赛人数与所在单位总人数所占比例进行评定。<br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;2.指导老师奖：优秀指导老师奖15名。获优秀组织奖的二级党组织各推荐1名。<br />
    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;3.个人奖：设100名。按照竞赛得分高低，前100名获得个人奖。若出现同分情况，则使用统计软件随机抽取。<br />
</p><p>
    （三）此次竞赛获奖情况将通过“中山大学统一战线”公众号公布，请扫以下二维码关注。<br />
</p><p>
    <!-- 在此处添加图片 -->
    <img src='@/assets/image2.jpg' alt="二维码图片" class="responsive-img" />

    （四）本活动解释权归主办方。<br />
</p>

<p style="text-align: right;">党委统战部</p>
<p style="text-align: right;">2024年9月*日</p>

      <button @click="startQuiz" class="start-btn">开始答题</button>
    </div>

    <div v-else>
      <h2>用户信息</h2>
      <div class="input-group">
        <label for="name">姓名:</label>
        <input type="text" id="name" v-model="name" placeholder="请输入姓名">
      </div>

      <div class="input-group">
        <label for="personType">类型:</label>
        <select id="personType" v-model="personType">
          <option value="" disabled>请选择身份</option>
          <option value="0">教工</option>
          <option value="1">学生</option>
        </select>
      </div>

      <div class="input-group">
        <label for="studentNumber">学号/职工号:</label>
        <input type="text" id="studentNumber" v-model="studentNumber" placeholder="请输入学号或职工号">
      </div>

      <div class="input-group" v-show="false">
        <label for="phoneNumber">联系方式:</label>
        <input type="text" id="phoneNumber" v-model="phoneNumber" placeholder="请输入联系方式"/>
      </div>

      <div class="input-group">
        <label for="organization">二级党组织:</label>
        <select id="organization" v-model="organization">
          <option disabled value="">请选择二级党组织</option>
<option>中国语言文学系党委</option>
<option>历史学系党委</option>
<option>哲学系党委</option>
<option>社会学与人类学学院党委</option>
<option>博雅学院党总支</option>
<option>岭南学院党委</option>
<option>外国语学院党委</option>
<option>法学院党委</option>
<option>政治与公共事务管理学院党委</option>
<option>管理学院党委</option>
<option>马克思主义学院党委</option>
<option>心理学系党委</option>
<option>新闻传播学院党委</option>
<option>信息管理学院党委</option>
<option>艺术学院党委</option>
<option>数学学院党委</option>
<option>物理学院党委</option>
<option>化学学院党委</option>
<option>地理科学与规划学院党委</option>
<option>生命科学学院党委</option>
<option>材料科学与工程学院党委</option>
<option>电子与信息工程学院党委</option>
<option>计算机学院党委</option>
<option>环境科学与工程学院党委</option>
<option>系统科学与工程学院党委</option>
<option>中山医学院党委</option>
<option>公共卫生学院党委</option>
<option>药学院党委</option>
<option>护理学院党委</option>
<option>体育部党委</option>
<option>中国语言文学系（珠海）党委</option>
<option>历史学系（珠海）党委</option>
<option>哲学系（珠海）党委</option>
<option>国际金融学院党委</option>
<option>国际翻译学院党委</option>
<option>国际关系学院党委</option>
<option>旅游学院党委</option>
<option>数学学院（珠海）党委</option>
<option>物理与天文学院党委</option>
<option>大气科学学院党委</option>
<option>海洋科学学院党委</option>
<option>地球科学与工程学院党委</option>
<option>化学工程与技术学院党委</option>
<option>海洋工程与技术学院党委</option>
<option>中法核工程与技术学院党委</option>
<option>土木工程学院党委</option>
<option>微电子科学与技术学院党委</option>
<option>测绘科学与技术学院党委</option>
<option>人工智能学院党委</option>
<option>软件工程学院党委</option>
<option>医学院党委</option>
<option>公共卫生学院（深圳）党委</option>
<option>药学院（深圳）党委</option>
<option>材料学院党委</option>
<option>生物医学工程学院党委</option>
<option>电子与通信工程学院党委</option>
<option>智能工程学院党委</option>
<option>航空航天学院党委</option>
<option>农业与生物技术学院党委</option>
<option>生态学院党委</option>
<option>集成电路学院直属党支部</option>
<option>先进制造学院直属党支部</option>
<option>先进能源学院直属党支部</option>
<option>网络空间安全学院直属党支部</option>
<option>商学院直属党支部</option>
<option>理学院直属党支部</option>
<option>柔性电子学院直属党支部</option>
<option>附属第一医院党委</option>
<option>孙逸仙纪念医院党委</option>
<option>附属第三医院党委</option>
<option>中山眼科中心党委</option>
<option>肿瘤防治中心党委</option>
<option>光华口腔医学院·附属口腔医院党委</option>
<option>附属第五医院党委</option>
<option>附属第六医院党委</option>
<option>附属第七医院党委</option>
<option>附属第八医院党委</option>
<option>离退休党工委</option>
<option>机关党委</option>
<option>文献与文化遗产管理部党委</option>
<option>产业集团党委</option>
<option>南方海洋科学与工程广东省实验室（珠海）党委</option>
<option>粤港澳发展研究院党委</option>        

	</select>
      </div>

      <button @click="submit" class="submit-btn">提交</button>
    </div>
  </div>
</template>

<style scoped>
/* Existing styles */

.container {
  max-width: 500px;
  margin: 40px auto;
  padding: 20px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background-color: #fff;
}

h2 {
  text-align: center;
  margin-bottom: 20px;
}

p {
  text-align: justify;
  margin-bottom: 20px;
}

.input-group {
  margin-bottom: 20px;
}

label {
  display: block;
  margin-bottom: 8px;
  font-weight: 500;
}

input, select {
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
}

.start-btn, .submit-btn {
  display: block;
  width: 100%;
  padding: 10px 15px;
  background-color: #007BFF;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  transition: background-color 0.3s;
}

.start-btn:hover, .submit-btn:hover {
  background-color: #0056b3;
}

.responsive-img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

</style>

<script>
import { ref, onBeforeUnmount } from 'vue';
import { useRouter } from 'vue-router';
import axios from 'axios';

export default {
  name: 'UserForm',
  setup() {
    const name = ref('');
    const studentNumber = ref('');
    const organization = ref('');
    const phoneNumber = ref('13333333333');
    const router = useRouter();
    const personType = ref('');
    const showIntroduction = ref(true);

    const startQuiz = () => {
      showIntroduction.value = false;
    };

window.alert = function(msg, callback) {
    var div = document.createElement("div");
    div.innerHTML = "<style type=\"text/css\">"
            + ".nbaMask { position: fixed; z-index: 1000; top: 0; right: 0; left: 0; bottom: 0; background: rgba(0, 0, 0, 0.5); }                                                                                                                                                                       "
            + ".nbaMaskTransparent { position: fixed; z-index: 1000; top: 0; right: 0; left: 0; bottom: 0; }                                                                                                                                                                                            "
            + ".nbaDialog { position: fixed; z-index: 5000; width: 80%; max-width: 300px; top: 50%; left: 50%; -webkit-transform: translate(-50%, -50%); transform: translate(-50%, -50%); background-color: #fff; text-align: center; border-radius: 8px; overflow: hidden; opacity: 1; color: white; }"
            + ".nbaDialog .nbaDialogHd { padding: .2rem .27rem .08rem .27rem; }                                                                                                                                                                                                                         "
            + ".nbaDialog .nbaDialogHd .nbaDialogTitle { font-size: 17px; font-weight: 400; }                                                                                                                                                                                                           "
            + ".nbaDialog .nbaDialogBd { padding: 0 .27rem; font-size: 15px; line-height: 1.3; word-wrap: break-word; word-break: break-all; color: #000000; }                                                                                                                                          "
            + ".nbaDialog .nbaDialogFt { position: relative; line-height: 48px; font-size: 17px; display: -webkit-box; display: -webkit-flex; display: flex; }                                                                                                                                          "
            + ".nbaDialog .nbaDialogFt:after { content: \" \"; position: absolute; left: 0; top: 0; right: 0; height: 1px; border-top: 1px solid #e6e6e6; color: #e6e6e6; -webkit-transform-origin: 0 0; transform-origin: 0 0; -webkit-transform: scaleY(0.5); transform: scaleY(0.5); }               "
            + ".nbaDialog .nbaDialogBtn { display: block; -webkit-box-flex: 1; -webkit-flex: 1; flex: 1; color: #09BB07; text-decoration: none; -webkit-tap-highlight-color: transparent; position: relative; margin-bottom: 0; }                                                                       "
            + ".nbaDialog .nbaDialogBtn:after { content: \" \"; position: absolute; left: 0; top: 0; width: 1px; bottom: 0; border-left: 1px solid #e6e6e6; color: #e6e6e6; -webkit-transform-origin: 0 0; transform-origin: 0 0; -webkit-transform: scaleX(0.5); transform: scaleX(0.5); }             "
            + ".nbaDialog a { text-decoration: none; -webkit-tap-highlight-color: transparent; }"
            + "</style>"
            + "<div id=\"dialogs2\" style=\"display: none\">"
            + "<div class=\"nbaMask\"></div>"
            + "<div class=\"nbaDialog\">"
            + "    <div class=\"nbaDialogHd\">"
            + "        <strong class=\"nbaDialogTitle\"></strong>"
            + "    </div>"
            + "    <div class=\"nbaDialogBd\" id=\"dialog_msg2\">弹窗内容，告知当前状态、信息和解决方法，描述文字尽量控制在三行内</div>"
            + "    <div class=\"nbaDialogHd\">"
            + "        <strong class=\"nbaDialogTitle\"></strong>"
            + "    </div>"
            + "    <div class=\"nbaDialogFt\">"
            + "        <a href=\"javascript:;\" class=\"nbaDialogBtn nbaDialogBtnPrimary\" id=\"dialog_ok2\">确定</a>"
            + "    </div></div></div>";
    document.body.appendChild(div);
 
    var dialogs2 = document.getElementById("dialogs2");
    dialogs2.style.display = 'block';
 
    var dialog_msg2 = document.getElementById("dialog_msg2");
    dialog_msg2.innerHTML = msg;
 
    // var dialog_cancel = document.getElementById("dialog_cancel");
    // dialog_cancel.onclick = function() {
    // dialogs2.style.display = 'none';
    // };
    var dialog_ok2 = document.getElementById("dialog_ok2");
    dialog_ok2.onclick = function() {
        dialogs2.style.display = 'none';
        callback();
    };
};


    // Add the following code to block console
    const blockConsole = (e) => {
      if (e.key === 'F12' || (e.ctrlKey && e.shiftKey && e.key === 'I')) {
        e.preventDefault();
      }
    };

    // Add the following code to block context menu
    const blockContextMenu = (e) => {
      e.preventDefault();
    };

    // Attach event listeners to block console and context menu
    document.addEventListener('keydown', blockConsole);
    document.addEventListener('contextmenu', blockContextMenu);

    onBeforeUnmount(() => {
      document.removeEventListener('keydown', blockConsole);
      document.removeEventListener('contextmenu', blockContextMenu);
    });

    const submit = async () => {
      if(!name.value.trim() || !studentNumber.value.trim() || !organization.value || !phoneNumber.value || !personType.value) {
        alert('请填写所有信息。');
        return;
      }
      try {
        const response = await axios.post('/api/user/submit', {
          name: name.value,
          studentNumber: studentNumber.value,
          organization: organization.value,
          phoneNumber: phoneNumber.value,
          personType: personType.value
        });

        if(response.data.message === "You can proceed with the quiz." || response.data.message === "User info submitted successfully! You can proceed with the quiz.") {
          router.push({ name: 'Quiz', params: { personType: personType.value, name: name.value, studentNumber: studentNumber.value, organization: organization.value } });
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        if (error.response && error.response.status === 403) {
          alert(error.response.data.message);
        } else if (error.response && error.response.status === 400) {
          alert(error.response.data.message);
        } else {
          console.error("Error while submitting user info:", error);
        }
      }
    };

    return {
      name,
      studentNumber,
      organization,
      phoneNumber,
      submit,
      personType,
      showIntroduction,
      startQuiz
    };
  }
}
</script>

